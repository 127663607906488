import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { map, catchError, tap } from 'rxjs/operators';
import { AUTH_UID_TYPE } from 'src/environments/constant';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private firestore : AngularFirestore) { }

  private extractData(res: Response, error: any) {
    let body: any = res;
    //console.log('Service complete');
    return body || {};
  }

  getMemberFromAuthUid(authUid){
    console.log('firebase service' , 'getMemberFromUser')
    return this.firestore.collection('members' , ref => ref.where('auth_uid' , '==' , authUid)).get()
  }

  getMemberBackupFromUser(uid : string){
    console.log('firebase service' , 'getMemberBackupFromUser')
    return this.firestore.collection('members_backup').doc(uid).get()
  }

  addNewMember(newMember){
    return this.firestore.collection('members').add(newMember)
  }

  updateMemberData(memberData : any , memberId : any){
    return this.firestore.collection('members').doc(memberId).update(memberData)
  }

  updateMemberDataFromUser(memberData : any , uid : any){
    this.firestore.collection('members' , ref => ref.where('auth_uid' , '==' , uid).limit(1)).get().subscribe(sub => {
      let docId = ""
      sub.forEach(element => {
        docId = element.id
      });
      return this.firestore.collection('members').doc(docId).update(memberData)
    })
  }

  getUniversitiesList(){
    return this.firestore.collection('constant_universities' , ref => ref.orderBy('university' , 'asc')).get()
  }

  getActivitiesList(){
    return this.firestore.collection('activities' , ref => ref.orderBy('start_date' , 'asc')).get()
  }

  getActivityFromActivityId(activityId : string){
    return this.firestore.collection('activities').doc(activityId).get()
  }

  getBookingFromMemberId(memberId : string){
    return this.firestore.collection('booking_activities' , ref => ref.where('member_id' , '==' , memberId)).get()
  }

  booking(memberId , acitivityId){
    const bookingData = {
      activity_id : acitivityId,
      member_id : memberId,
      booking_date : new Date(),
      status : 'booking'
    }
    this.firestore.collection('booking_activities').add(bookingData).then(resp => {
      this.firestore.collection('members').doc(memberId).collection('booking_activities').doc(resp.id).set(bookingData).then(memberResp => {
        return true
      })
    })
  }

  addProvice(){
    const province = ["Bangkok",
    "Amnat Charoen",
    "Ang Thong",
    "Bueng Kan",
    "Buriram",
    "Chachoengsao",
    "Chai Nat",
    "Chaiyaphum",
    "Chanthaburi",
    "Chiang Mai",
    "Chiang Rai",
    "Chonburi",
    "Chumphon",
    "Kalasin",
    "Kamphaeng Phet",
    "Kanchanaburi",
    "Khon Kaen",
    "Krabi",
    "Lampang",
    "Lamphun",
    "Loei",
    "Lopburi",
    "Mae Hong Son",
    "Maha Sarakham",
    "Mukdahan",
    "Nakhon Nayok",
    "Nakhon Pathom",
    "Nakhon Phanom",
    "Nakhon Ratchasima",
    "Nakhon Sawan",
    "Nakhon Si Thammarat",
    "Nan",
    "Narathiwat",
    "Nong Bua Lam Phu",
    "Nong Khai",
    "Nonthaburi",
    "Pathum Thani",
    "Pattani",
    "Phang Nga",
    "Phatthalung",
    "Phayao",
    "Phetchabun",
    "Phetchaburi",
    "Phichit",
    "Phitsanulok",
    "Phra Nakhon Si Ayutthaya",
    "Phrae",
    "Phuket",
    "Prachinburi",
    "Prachuap Khiri Khan",
    "Ranong",
    "Ratchaburi",
    "Rayong",
    "Roi Et",
    "Sa Kaeo",
    "Sakon Nakhon",
    "Samut Prakan",
    "Samut Sakhon",
    "Samut Songkhram",
    "Saraburi",
    "Satun",
    "Sing Buri",
    "Sisaket",
    "Songkhla",
    "Sukhothai (Sukhothai Thani)",
    "Suphan Buri",
    "Surat Thani",
    "Surin",
    "Tak",
    "Trang",
    "Trat",
    "Ubon Ratchathani",
    "Udon Thani",
    "Uthai Thani",
    "Uttaradit",
    "Yala",
    "Yasothon"]

    const promiseArray = []

    console.log('Start add province')

    province.forEach(element => {

      promiseArray.push(
        new Promise((resolve , reject) => {
          this.firestore.collection('constant_provinces').add({province : element}).then(resp => {
            resolve(true)
          }).catch(err => {
            reject(false)
          })
        })
      )

    });

    Promise.all(promiseArray).then(resp => {
      console.log('Add province complete')
    }).catch(err => {
      console.error('Add province error')
    })
  }

  getProvincesList(){
    return this.firestore.collection('constant_provinces' , ref => ref.orderBy('province' , 'asc')).get()
  }


}
