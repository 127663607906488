import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AngularFireAuth } from "@angular/fire/auth";

import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/do';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response, error: any) {
    let body: any = res;
    //console.log('Service complete');
    return body || {};
  }

  httpGetRequest(uri: any, service: any, parameter: any): Observable<any> {
    //console.log('Service : getRequest', service);

    const httpOptions = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    return this.http.get(uri + service + '/' + parameter, httpOptions).pipe(
      map(this.extractData)
    );
  }

  httpPostRequest(uri: any, service: any, data: any): Observable<any> {
    //console.log('Service : postRequest', service);

    const httpOptions = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    return this.http.post(uri + service, data, httpOptions).pipe(
      map(this.extractData)
    );
  }



}
