import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogState } from '@angular/material';

import {
  trigger, state, style, transition, animate, group, query, stagger, keyframes
} from '@angular/animations';
import { ConnectionService } from '../service/connection.service';

import { FIREBASE_SERVICE_URL, FACEBOOK_SERVICE_URL, FACEBOOK_SERVICE_CONSTANT } from 'src/environments/constant';
import { Router } from '@angular/router';

import QRCode from 'qrcode'

const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
  ]),
];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('buttonState', [
      state('right', style({
        backgroundColor: 'red',
        transform: 'translateX(-200%)'
      })),
      state('left', style({
        backgroundColor: 'green',
        transform: 'translateX(200%)'
      })),
      transition('* => right', animate('1s ease-in')),
      transition('* => left', animate('1s ease-out'))
    ])
  ]
})
export class HomeComponent implements OnInit {

  newsList = []
  newsCollection = []
  newsPage = 1
  newsPageSize = 8

  researchList = []
  locationList = []

  eventList = []
  eventCollection = []
  eventPage = 1
  eventPageSize = 3

  supportList = []

  state: String = ''

  displaySpaceURL = ""
  newsIndex = 0
  newsShift = ''

  uid: any
  member: any = {}
  user: any = {}
  displayname = ""
  isFrontCard = true

  @ViewChild("processingTemplate", { static: true })
  private processingTemplate: TemplateRef<any>;
  processingTemplateRef: MatDialogRef<any>;

  @ViewChild("completeTemplate", { static: true })
  private completeTemplate: TemplateRef<any>;
  completeTemplateRef: MatDialogRef<any>;

  openProcessingTemplate(): void {
    this.processingTemplateRef = this.dialog.open(this.processingTemplate, {
      width: '300px'
    });

    this.processingTemplateRef.afterClosed();
  }

  openCompleteTemplate(): void {
    this.completeTemplateRef = this.dialog.open(this.completeTemplate, {
      width: '300px'
    });

    this.completeTemplateRef.afterOpened().subscribe(_ => {
      const self = this
      setTimeout(() => {
        self.completeTemplateRef.close();
      }, 1500)
    });
  }


  ngOnInit() {
  }

  constructor(private element: ElementRef, public dialog: MatDialog, private connectionService: ConnectionService, public router: Router) {
    console.log('constructor')
    // this.prepareNewsList()
    this.prepareResearchList()
    this.prepareLocationList()
    // this.prepareEventList()
    this.prepareSupportList()
    console.log('constructor complete')
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.user = this.authService.authUser
    // console.log('member', this.member)
    // console.log('user', this.user)
    window.scrollTo(0, 0);
  }



  prepareNewsList() {

    this.newsList = []
    this.connectionService.httpGetRequest(FIREBASE_SERVICE_URL.contentURL, 'getFacebookToken', '').subscribe((resp: any) => {
      let facebookToken = ""
      if (resp && resp.status == 'Complete') {
        facebookToken = resp.data.facebook_token
        console.log('fb token' , facebookToken)

        this.connectionService.httpGetRequest(FACEBOOK_SERVICE_URL.facebookGraphURL, '111606876942254/feed', '?fields=full_picture%2Cpermalink_url%2Cmessage%2Cid&access_token=' + facebookToken).subscribe((resp: any) => {
          // console.log('news List', resp)
          resp.data.forEach(element => {
            if (element.message != undefined || element.message != null) {
              this.newsList.push(element)
            }
          });
          this.changeNewsPage()
        })
      }
    })



  }

  changeNewsPage() {
    this.newsCollection = this.newsList
      .map((news, i) => ({ id: i + 1, ...news }))
      .slice((this.newsPage - 1) * this.newsPageSize, (this.newsPage - 1) * this.newsPageSize + this.newsPageSize);
  }

  prepareResearchList() {

    this.researchList = []
    this.connectionService.httpGetRequest(FIREBASE_SERVICE_URL.contentURL, 'getResearch', '').subscribe((resp: any) => {
      if (resp && resp.status == 'Complete') {
        this.researchList = resp.data
      }
    })

  }

  prepareLocationList() {

    this.displaySpaceURL = 'assets/img/AIS_PG_logo_white.png'
    this.locationList = []
    this.connectionService.httpGetRequest(FIREBASE_SERVICE_URL.contentURL, 'getSpace', '').subscribe((resp: any) => {
      if (resp && resp.status == 'Complete') {
        this.locationList = resp.data
      }
    })
  }

  prepareEventList() {

    this.eventList = []
    this.connectionService.httpGetRequest(FIREBASE_SERVICE_URL.contentURL, 'getFacebookToken', '').subscribe((resp: any) => {
      let facebookToken = ""
      if (resp && resp.status == 'Complete') {
        facebookToken = resp.data.facebook_token
        this.connectionService.httpGetRequest(FACEBOOK_SERVICE_URL.facebookGraphURL, '111606876942254/events', '?access_token=' + facebookToken + FACEBOOK_SERVICE_CONSTANT.facebookEventConstant).subscribe((resp: any) => {
          this.eventList = resp.data
          this.changeEventPage()
        })
      }
    })



  }


  changeEventPage() {
    // console.log('eventPageSize', this.eventPageSize)
    // console.log('eventPage', this.eventPage)

    this.eventCollection = this.eventList
      .map((event, i) => ({ id: i + 1, ...event }))
      .slice((this.eventPage - 1) * this.eventPageSize, (this.eventPage - 1) * this.eventPageSize + this.eventPageSize);
    // console.log('eventCollection', this.eventCollection)
  }

  prepareSupportList() {

    this.supportList = []
    this.connectionService.httpGetRequest(FIREBASE_SERVICE_URL.contentURL, 'getSupport', '').subscribe((resp: any) => {
      if (resp && resp.status == 'Complete') {
        this.supportList = resp.data
      }
    })
  }

  displaySpace(location) {
    this.displaySpaceURL = location.img
  }

  newsNext() {
    // console.log('newsNext', this.newsIndex)
    this.state = 'right'
    if (this.newsPage < this.newsPageSize) {
      this.newsPage++;
      this.changeNewsPage()
      // console.log('newsNext', this.newsPage)
    } else {
      this.newsPage = 1
      this.changeNewsPage()
    }
  }

  newsPrevious() {
    // console.log('newsPrevious', this.newsIndex)
    this.state = 'left'
    if (this.newsIndex > 0) {
      this.newsPage = this.newsPage--;
      this.changeNewsPage()
      // console.log('newsNext', this.newsIndex)
    }
  }

  eventNext() {
    if (this.eventPage < this.eventPageSize) {
      this.eventPage++;
      this.changeEventPage()
    } else {
      this.eventPage = 1
      this.changeEventPage()
    }
  }

  eventPrevious() {
    if (this.eventPage > 0) {
      this.eventPage--;
      this.changeEventPage()
    }
  }

  locationNameForGoogleMap(name: any) {
    let locationName = name.replaceAll(' ', '+')
    locationName = name.replaceAll(',', '%2C')
    return locationName
  }

  contact(name, email, message) {
    this.openProcessingTemplate()
    console.log('contact', name, email, message)
    const self = this
    const data = {
      name: name,
      email: email,
      message: message
    }
    this.connectionService.httpPostRequest(FIREBASE_SERVICE_URL.adminURL, 'contact', data).subscribe((resp: any) => {
      self.processingTemplateRef.close()
      if (resp && resp.status == 'Complete') {
        self.openCompleteTemplate()
      } else {
        window.alert('Please rty again')
      }
    })
  }



}
