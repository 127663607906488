import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './membership/layout/layout.component';
import { ResearchDetailComponent } from './research-detail/research-detail.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'research-detail/:researchId', component: ResearchDetailComponent },
  {
    path: 'membership', component: LayoutComponent ,
    children: [
      {
          path: '',
          loadChildren: './membership/membership.module#MembershipModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
