import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Location } from "@angular/common";
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentRoute = "home"

  constructor(private location: Location, public router: Router , public authService : AuthService) {

  }

  routerTo(target) {
    if (target == 'playground') {
      this.currentRoute = target
      this.router.navigate([''])
    } else {
      this.currentRoute = target
      this.router.navigate(['membership/' + target])
    }
  }

  isCurrentRoute(route) {
    let current = this.router.url
    let pathArray = current.split('/')
    let index = pathArray.indexOf('membership')

    let activeRoute = pathArray[index + 1]
    this.currentRoute = activeRoute
    return this.currentRoute === route
  }

  ngOnInit() {
  }



}
