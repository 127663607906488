import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from '../services/firebase.service';

import { IMAGE_ACTIVITIES_URL } from './../../../environments/constant'

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  activitiesList : any

  activityURL = IMAGE_ACTIVITIES_URL
  selectActivity : any
  user : any = {}
  memberData : any = {}

  bookingList : any = []

  @ViewChild("cardTemplate", { static: true })
  private cardTemplate: TemplateRef<any>;
  cardTemplateRef: MatDialogRef<any>;

  openCardTemplate(activity : any): void {

    this.selectActivity = activity

    let vh = window.innerHeight
    console.log('vh' , vh)

    if (this.cardTemplateRef == undefined || this.cardTemplateRef.getState() == MatDialogState.CLOSED) {
      this.cardTemplateRef = this.dialog.open(this.cardTemplate, {
        width: '100vw',
        height: `${vh}px`,
        panelClass: 'card-dialog',
        maxWidth : '100vw' ,
        maxHeight: `${vh}px`,
      });

      this.cardTemplateRef.afterClosed();
    }
  }

  constructor(private element: ElementRef, public dialog: MatDialog , public router : Router , private firebaseService : FirebaseService) {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getMemberData()
    this.getActivities()
  }

  ngOnInit() {
  }

  getMemberData(){
    this.memberData = JSON.parse(localStorage.getItem('member'))
    if (this.memberData == null){
      this.firebaseService.getMemberFromAuthUid(this.user.uid).subscribe(sub => {
        sub.forEach(element => {
          this.memberData = {id : element.id ,... element.data()}
        });
        localStorage.setItem('member', JSON.stringify(this.memberData));
        this.getBookingList()
      })
    }else{
      this.getBookingList()
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

  getActivities(){

    this.activitiesList = []

    this.firebaseService.getActivitiesList().subscribe(sub => {
      sub.forEach(element => {
        this.activitiesList.push({id : element.id ,... element.data()})
      });
    })
  }

  isBooking(){
    console.log('check isBooking')
    let result = false
    this.bookingList.forEach(bookingData => {
      if(bookingData.member_id == this.memberData.id){
        result = true
      }
    });
    return result
  }

  getBookingList(){
    this.bookingList = []
    console.log('getBookingList' , this.memberData.id)
    this.firebaseService.getBookingFromMemberId(this.memberData.id).subscribe(sub => {
      sub.forEach(element => {
        let booking : any = {id : element.id ,... element.data()}
        this.firebaseService.getActivityFromActivityId(booking.activity_id).subscribe(actSub => {
          booking.activity = {id : actSub.id ,... actSub.data()}
          console.log('booking' , booking)
        })
        this.bookingList.push(booking)
      });
    })
  }

  booking(){
    if(window.confirm("Confirm booking this event ?")){
      this.firebaseService.booking(this.memberData.id , this.selectActivity.id)
      window.alert('Booking complete')
      this.cardTemplateRef.close()
      this.router.navigate(['/membership/home'])
    }

  }

}
