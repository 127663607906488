import { Route } from '@angular/compiler/src/core';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AUTH_UID_TYPE } from 'src/environments/constant';
import { AuthService } from '../services/auth.service';
import { FacebookGraphApiService } from '../services/facebook-graph-api.service';
import { FirebaseService } from '../services/firebase.service';

import { MatDialog, MatDialogRef, MatDialogState, MatSelectModule } from '@angular/material';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  memberData: any = {}
  user: any = {}

  authen_email_state = false
  authen_email_name = ""

  authen_google_state = false
  authen_google_name = ""

  authen_facebook_state = false
  authen_facebook_name = ""

  authen_twitter_state = false
  authen_twitter_name = ""

  universitiesList: any
  provincesList : any

  linkAuthEmailAlertMessage = ""

  @ViewChild("linkAuthEmailTemplate", { static: true })
  private linkAuthEmailTemplate: TemplateRef<any>;
  linkAuthEmailTemplateRef: MatDialogRef<any>;

  openLinkAuthEmailTemplate(): void {
    let vh = window.innerHeight
    console.log('vh', vh)

    if (this.linkAuthEmailTemplateRef == undefined || this.linkAuthEmailTemplateRef.getState() == MatDialogState.CLOSED) {
      this.linkAuthEmailTemplateRef = this.dialog.open(this.linkAuthEmailTemplate, {
        width: '100vw',
        height: `${vh}px`,
        panelClass: 'card-dialog',
        maxWidth: '100vw',
        maxHeight: `${vh}px`
      });

      this.linkAuthEmailTemplateRef.afterClosed();
    }
  }

  constructor(public dialog: MatDialog, private router: Router, private authService: AuthService, private firebaseService: FirebaseService, private facebookService: FacebookGraphApiService) {
    // this.mockupProfileData()
    this.user = JSON.parse(localStorage.getItem('user'))
    console.log('user from profile', this.user)
    this.getMemberData()
    this.getUniversitiesList()
    this.getProvincesList()
  }

  getUniversitiesList() {
    this.universitiesList = []
    this.firebaseService.getUniversitiesList().subscribe(sub => {
      sub.forEach(element => {
        this.universitiesList.push({ id: element.id, ...element.data() })
      });
    })
  }

  getProvincesList() {
    this.provincesList = []
    this.firebaseService.getProvincesList().subscribe(sub => {
      sub.forEach(element => {
        this.provincesList.push({ id: element.id, ...element.data() })
      });
    })
  }

  ngOnInit() {
  }

  back() {
    this.router.navigate(['/membership'])
  }

  // Link Account

  checkAccountForUnlink() {
    const authProvider: [] = this.user.providerData

    if (authProvider.length <= 1) {
      return false
    }
    else {
      return true
    }
  }

  onAuthenEmailChange() {
    if (this.authen_email_state == false) {
      this.openLinkAuthEmailTemplate()
    } else {
      if (this.checkAccountForUnlink()) {
        if (window.confirm("Unlink account?")) {
          this.authService.unlinkEmailAuth()
        }
      } else {
        window.alert('Can not unlink account. You have only 1 account')
      }
    }
  }

  toggleLinkAuthEmailButton(result: any) {
    if (result) {
      document.getElementById('link_auth_email_button').style.backgroundColor = 'red'
      document.getElementById('link_auth_email_button').innerHTML = 'Unlink'
      this.authen_email_state = true
    } else {
      document.getElementById('link_auth_email_button').style.backgroundColor = '#72ad21'
      document.getElementById('link_auth_email_button').innerHTML = 'Link'
      this.authen_email_state = false
    }
  }

  onAuthenFacebookChange() {
    if (this.authen_facebook_state == false) {
      if (window.confirm("Link with your facebook account?")) {
        this.authService.linkFacebookAuth()
        this.user.providerData.forEach(provider => {
          if (provider.providerId == 'facebook.com') {
            this.authen_facebook_name = provider.displayName
          }
        });
        this.toggleLinkAuthFacebookButton(true)
      } else {
        this.toggleLinkAuthFacebookButton(false)
      }
    } else {
      if (this.checkAccountForUnlink()) {
        if (window.confirm("Unlink account?")) {
          this.authService.unlinkFacebookAuth()
        }
      } else {
        window.alert('Can not unlink account. You have only 1 account')
      }
    }
  }

  toggleLinkAuthFacebookButton(result: any) {
    if (result) {
      document.getElementById('link_auth_facebook_button').style.backgroundColor = 'red'
      document.getElementById('link_auth_facebook_button').innerHTML = 'Unlink'
      this.authen_facebook_state = true
    } else {
      document.getElementById('link_auth_facebook_button').style.backgroundColor = '#72ad21'
      document.getElementById('link_auth_facebook_button').innerHTML = 'Link'
      this.authen_facebook_state = false
    }
  }

  onAuthenGoogleChange() {
    console.log('onAuthenGoogleChange', event)
    if (this.authen_google_state == false) {
      if (window.confirm("Link with your facebook account?")) {
        this.authService.linkFacebookAuth()
        this.user.providerData.forEach(provider => {
          if (provider.providerId == 'google.com') {
            this.authen_google_name = provider.displayName
          }
        });
        this.toggleLinkAuthGoogleButton(true)
      } else {
        this.toggleLinkAuthGoogleButton(false)
      }
    } else {
      if (this.checkAccountForUnlink()) {
        if (window.confirm("Unlink account?")) {
          this.authService.unlinkGoogleAuth()
        }
      } else {
        window.alert('Can not unlink account. You have only 1 account')
      }
    }
  }

  toggleLinkAuthGoogleButton(result: any) {
    if (result) {
      document.getElementById('link_auth_google_button').style.backgroundColor = 'red'
      document.getElementById('link_auth_google_button').innerHTML = 'Unlink'
      this.authen_google_state = true
    } else {
      document.getElementById('link_auth_google_button').style.backgroundColor = '#72ad21'
      document.getElementById('link_auth_google_button').innerHTML = 'Link'
      this.authen_google_state = false
    }
  }

  onAuthenTwitterChange() {
    console.log('onAuthenTwitterChange', event)
    if (this.authen_twitter_state == false) {
      if (window.confirm("Link with your twitter account?")) {
        this.authService.linkTwitterAuth()
        this.user.providerData.forEach(provider => {
          if (provider.providerId == 'twitter.com') {
            this.authen_twitter_name = provider.displayName
          }
        });
        this.toggleLinkAuthTwitterButton(true)
      } else {
        this.toggleLinkAuthTwitterButton(false)
      }
    } else {
      if (this.checkAccountForUnlink()) {
        if (window.confirm("Unlink account?")) {
          this.authService.unlinkTwitterAuth()
        }
      } else {
        window.alert('Can not unlink account. You have only 1 account')
      }
    }
  }

  toggleLinkAuthTwitterButton(result: any) {
    if (result) {
      document.getElementById('link_auth_twitter_button').style.backgroundColor = 'red'
      document.getElementById('link_auth_twitter_button').innerHTML = 'Unlink'
      this.authen_twitter_state = true
    } else {
      document.getElementById('link_auth_twitter_button').style.backgroundColor = '#72ad21'
      document.getElementById('link_auth_twitter_button').innerHTML = 'Link'
      this.authen_twitter_state = false
    }
  }




  changeImage() {
    console.log('changeImage click')
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    window.scrollTo(0, 0);
  }

  getMemberData() {
    console.log('getMemberData')
    this.user = JSON.parse(localStorage.getItem('user'))
    this.firebaseService.getMemberFromAuthUid(this.user.uid).subscribe(sub => {
      sub.forEach(element => {
        this.memberData = { id: element.id, ...element.data() }
      });
      this.toggleLinkAuthFacebookButton(false)
      this.checkAuthState()
    })
  }

  checkAuthState() {
    console.log('checkAuthState', this.user)
    const providerData = this.user.providerData
    providerData.forEach(provider => {
      if (provider.providerId == 'password') {
        this.authen_email_state = true
        this.authen_email_name = provider.email
        this.toggleLinkAuthEmailButton(true)
      }
      else if (provider.providerId == 'facebook.com') {
        this.authen_facebook_state = true
        this.authen_facebook_name = provider.displayName
        this.toggleLinkAuthFacebookButton(true)
      }
      else if (provider.providerId == 'google.com') {
        this.authen_google_state = true
        this.authen_google_name = provider.displayName
        this.toggleLinkAuthGoogleButton(true)
      }
      else if (provider.providerId == 'twitter.com') {
        this.authen_twitter_state = true
        this.authen_twitter_name = provider.displayName
        this.toggleLinkAuthTwitterButton(true)
      }

    });
  }

  updateMemberData(){
    if (window.confirm('Confirm update profile')){
      console.log('new profile' , this.memberData)
      this.firebaseService.updateMemberData(this.memberData , this.memberData.id).then(resp => {
        window.alert('Update profile Complete')
        location.reload()
      }).catch(err => {
        window.alert(err)
      })
    }
  }


  signout() {
    this.authService.signout()
  }

  LinkAuthWithEmail(form: NgForm) {

    const data = form.value

    console.log('LinkAuthWithEmail' , data)

    const email = data.email
    const password = data.password
    const confirmPassword = data.confirmPassword

    if (email == "" || password == "" || confirmPassword == "") {
      this.linkAuthEmailAlertMessage = "Please enter information"
    }else if (password != confirmPassword) {
      this.linkAuthEmailAlertMessage = "Password not match"
    } else {
      this.linkAuthEmailTemplateRef.close()
      this.authService.linkEmailAuth(email, password)
      this.user.providerData.forEach(provider => {
        if (provider.providerId == 'password') {
          this.authen_email_name = provider.email
        }
      });
      this.toggleLinkAuthEmailButton(true)
    }
  }

}
