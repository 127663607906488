import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MatDialogState } from '@angular/material';
import { Router } from '@angular/router';

import QRCode from 'qrcode'
import { IMAGE_ACTIVITIES_URL } from 'src/environments/constant';
import { AuthService } from '../services/auth.service';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild("cardTemplate", { static: true })
  private cardTemplate: TemplateRef<any>;
  cardTemplateRef: MatDialogRef<any>;

  activityURL = IMAGE_ACTIVITIES_URL

  memberData : any = {}
  user : any = {}

  bookingList : any = []
  selectBooking : any = {}

  openCardTemplate(): void {
    let vh = window.innerHeight
    console.log('vh' , vh)


    if (this.cardTemplateRef == undefined || this.cardTemplateRef.getState() == MatDialogState.CLOSED) {
      this.cardTemplateRef = this.dialog.open(this.cardTemplate, {
        width: '100vw',
        height: `${vh}px`,
        panelClass: 'card-dialog',
        maxWidth : '100vw' ,
        maxHeight : `${vh}px`
      });

      this.cardTemplateRef.afterClosed();

      let cardHeight = document.getElementById('profile-card-body')
      console.log('cardHeight' , cardHeight.offsetHeight)

      cardHeight.style.paddingTop = (vh - cardHeight.offsetHeight)/3 + 'px';

      this.generateQR()
    }
  }

  @ViewChild("bookingDetailTemplate", { static: true })
  private bookingDetailTemplate: TemplateRef<any>;
  bookingDetailTemplateRef: MatDialogRef<any>;

  openBookingDetailTemplate(booking): void {
    let vh = window.innerHeight
    console.log('vh' , vh)

    this.selectBooking = booking

    if (this.bookingDetailTemplateRef == undefined || this.bookingDetailTemplateRef.getState() == MatDialogState.CLOSED) {
      this.bookingDetailTemplateRef = this.dialog.open(this.bookingDetailTemplate, {
        width: '100vw',
        height: `${vh}px`,
        panelClass: 'card-dialog',
        maxWidth : '100vw' ,
        maxHeight: `${vh}px`
      });

      this.bookingDetailTemplateRef.afterClosed();
    }
  }



  constructor(private authService : AuthService ,  private element: ElementRef, public dialog: MatDialog , public router : Router , private firebaseService : FirebaseService) {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getMemberData()
  }

  ngOnInit() {
  }


  generateQR(){
    var canvas = document.getElementById('qrcanvas')

    QRCode.toCanvas(canvas, this.memberData.id , function (error) {
      if (error) console.error(error)
      console.log('success!');
      document.getElementById('qrcanvas').style.width = '25vh'
      document.getElementById('qrcanvas').style.height = '25vh'
      document.getElementById('qrcanvas').style.borderRadius = '10%'
    })
  }

  goToProfile(){
    this.router.navigate(['/membership/profile'])
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    window.scrollTo(0, 0);

    console.log('ngAfterViewInit' , this.user)
  }

  getMemberData(){
    this.memberData = JSON.parse(localStorage.getItem('member'))
    if (this.memberData == null){
      this.firebaseService.getMemberFromAuthUid(this.user.uid).subscribe(sub => {
        sub.forEach(element => {
          this.memberData = {id : element.id ,... element.data()}
        });
        localStorage.setItem('member', JSON.stringify(this.memberData));
        this.getBookingList()
      })
    }else{
      this.getBookingList()
    }
  }

  getBookingList(){
    this.bookingList = []
    console.log('getBookingList' , this.memberData.id)
    this.firebaseService.getBookingFromMemberId(this.memberData.id).subscribe(sub => {
      sub.forEach(element => {
        let booking : any = {id : element.id ,... element.data()}
        this.firebaseService.getActivityFromActivityId(booking.activity_id).subscribe(actSub => {
          booking.activity = {id : actSub.id ,... actSub.data()}
          console.log('booking' , booking)
        })
        this.bookingList.push(booking)
      });
    })
  }

}
