import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseService } from './firebase.service';

import { AUTH_UID_TYPE } from './../../../environments/constant'

import * as firebaseui from 'firebaseui'


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth, private ngZone: NgZone, private router: Router, private firestore: AngularFirestore, private firebaseService: FirebaseService) {
    this.checkAuth()
  }

  checkAuth() {
    console.log('checkAuth')
    this.afAuth.authState.subscribe(user => {
      console.log('user', user)
      if (user) {
        localStorage.clear();
        localStorage.setItem('user', JSON.stringify(user));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  getAccessToken() {
    return firebase.auth().currentUser.getIdTokenResult()
  }

  signout() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user')
      this.router.navigate(['/'])
    })
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  initFirebaseAuth() {

    const self = this

    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.

          console.log('authResult', authResult)
          var user = authResult.user;
          localStorage.setItem('fbAuth', JSON.stringify(user))


          self.checkMember(user)

          return false;
        },
        uiShown: function () {
          // The widget is rendered.
          // Hide the loader.
          // document.getElementById('loader').style.display = 'none';
        }
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      // signInSuccessUrl: 'membership/',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          scopes: [
            'https://www.googleapis.com/auth/contacts.readonly'
          ],
          customParameters: {
            // Forces account selection even when one account
            // is available.
            prompt: 'select_account'
          }
        },
        {
          provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          scopes: [
            'public_profile',
            'email',
            'user_likes',
            'user_friends'
          ],
          customParameters: {
            // Forces password re-entry.
            // auth_type: 'reauthenticate'
          }
        },
        firebase.auth.TwitterAuthProvider.PROVIDER_ID
      ]
    };

    let firebaseAuthUI = new firebaseui.auth.AuthUI(firebase.auth());
    firebaseAuthUI.start('#firebaseui-auth-container', uiConfig);
  }

  signinWithEmail(username, password) {

    firebase.auth().signInWithEmailAndPassword(username, password).then(result => {
      // The signed-in user info.
      var user = result.user;
      localStorage.setItem('user', JSON.stringify(user));

      this.checkMember(user)
    }).catch(error => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      window.alert('Invalid username or password : ' + errorMessage)
    })
  }

  signinWithFacebook() {

    var provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // The signed-in user info.
        var user = result.user;
        console.log('fb user', user)

        localStorage.setItem('user', JSON.stringify(user));

        this.checkMember(user)

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        window.alert('Error signin with facebook : ' + errorMessage)
        // ...
      });
  }

  checkMember(user: any) {

    console.log('check member', user.uid)
    var memberData: any = []

    this.firebaseService.getMemberFromAuthUid(user.uid).subscribe(sub => {

      sub.forEach(element => {
        memberData = { id: element.id, ...element.data() }
      });

      console.log('member', memberData)

      if (memberData.length == 0) {

        this.checkForMigrateMember(user)

      } else {

        this.router.navigate(['/membership/home'])

      }
    })

  }

  checkForMigrateMember(user: any) {

    console.log('check old member', user.uid)

    this.firebaseService.getMemberBackupFromUser(user.uid).subscribe(sub => {

      console.log('old member', sub.data())
      if (sub.data() !== undefined) {
        const data = sub.data()

        let firstname = ""
        let lastname = ""

        if (data.lastname !== undefined) {
          firstname = data.name
          lastname = data.lastname
        } else {
          const fullname = data.name
          const nameArray = fullname.split(' ')

          for (let i = 1; i < nameArray.lenght; i++) {
            lastname += nameArray[i] + " "
          }
          firstname = nameArray[0],
            lastname = lastname.trim()
        }


        let newMemberData: any = {
          firstname: firstname,
          lastname: lastname,
          register_date: new Date(data.register_date.seconds),
          point: 0,
          auth_uid: user.uid,
          photoURL: ''
        }

        console.log('new member data', newMemberData)

        this.firebaseService.addNewMember(newMemberData).then(() => {
          this.router.navigate(['/membership/home'])
        }).catch(error => {
          window.alert('Sign in Error. Please try again.')
        })
      } else {
        console.log('New user')

        let newMemberData: any = {
          firstname: user.displayName,
          lastname: '',
          email: user.email,
          register_date: new Date(),
          point: 0,
          auth_uid: user.uid,
          photoURL: ''
        }

        this.firebaseService.addNewMember(newMemberData).then(() => {
          this.router.navigate(['/membership/home'])
        }).catch(error => {
          window.alert('Sign in Error. Please try again.')
        })
      }
    })
  }

  // Link Account

  linkEmailAuth(email , password) {

    var credential = firebase.auth.EmailAuthProvider.credential(email, password);

    firebase.auth().currentUser.linkWithCredential(credential).then(function (result) {
      // Accounts successfully linked.

      var user = result.user;

      localStorage.setItem('user', JSON.stringify(user));
      JSON.parse(localStorage.getItem('user'));

      window.alert('Link account complete')
      location.reload()


      // ...
    }).catch(function (error) {
      window.alert('Link account Error : ' + error.message)
      location.reload()
    });
  }

  unlinkEmailAuth() {

    firebase.auth().currentUser.unlink('password').then(function (user) {
      // Auth provider unlinked from account
      // ...
      console.log('unlinkEmailAuth', user)
      localStorage.setItem('user', JSON.stringify(user));
      JSON.parse(localStorage.getItem('user'));

      window.alert('Unlink account complete')
      location.reload()

    }).catch(function (error) {
      // An error happened
      // ...
    });

  }

  linkFacebookAuth() {

    var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('public_profile')
    const self = this

    firebase.auth().currentUser.linkWithPopup(provider).then(function (result) {
      // Accounts successfully linked.
      var credential = result.credential;
      var user = result.user;

      localStorage.setItem('user', JSON.stringify(user));
      JSON.parse(localStorage.getItem('user'));

      const self = this

      window.alert('Link account complete')
      location.reload()


      // ...
    }).catch(error => {
      window.alert('Link account Error : ' + error.message)
      location.reload()
    });

  }

  unlinkFacebookAuth() {

    firebase.auth().currentUser.unlink('facebook.com').then(function (user) {
      // Auth provider unlinked from account
      // ...
      console.log('unlinkFacebookAuth', user)
      localStorage.setItem('user', JSON.stringify(user));
      JSON.parse(localStorage.getItem('user'));

      window.alert('Unlink account complete')
      location.reload()

    }).catch(function (error) {
      // An error happened
      // ...
    });

  }

  linkGoogleAuth() {

    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('public_profile')
    const self = this

    firebase.auth().currentUser.linkWithPopup(provider).then(function (result) {
      // Accounts successfully linked.
      var credential = result.credential;
      var user = result.user;

      localStorage.setItem('user', JSON.stringify(user));
      JSON.parse(localStorage.getItem('user'));

      const self = this

      window.alert('Link account complete')
      location.reload()


      // ...
    }).catch(function (error) {
      window.alert('Link account Error : ' + error.message)
      location.reload()
    });

  }

  unlinkGoogleAuth() {

    firebase.auth().currentUser.unlink('google.com').then(function (user) {
      // Auth provider unlinked from account
      // ...
      console.log('unlinkGoogleAuth', user)
      localStorage.setItem('user', JSON.stringify(user));
      JSON.parse(localStorage.getItem('user'));

      window.alert('Unlink account complete')
      location.reload()

    }).catch(function (error) {
      // An error happened
      // ...
    });

  }

  linkTwitterAuth() {

    var provider = new firebase.auth.TwitterAuthProvider()
    const self = this

    firebase.auth().currentUser.linkWithPopup(provider).then(function (result) {
      // Accounts successfully linked.
      var credential = result.credential;
      var user = result.user;

      localStorage.setItem('user', JSON.stringify(user));
      JSON.parse(localStorage.getItem('user'));

      const self = this

      window.alert('Link account complete')
      location.reload()


      // ...
    }).catch(function (error) {
      window.alert('Link account Error : ' + error.message)
      location.reload()
    });

  }

  unlinkTwitterAuth() {

    firebase.auth().currentUser.unlink('twotter.com').then(function (user) {
      // Auth provider unlinked from account
      // ...
      console.log('unlinkTwitterAuth', user)
      localStorage.setItem('user', JSON.stringify(user));
      JSON.parse(localStorage.getItem('user'));

      window.alert('Unlink account complete')
      location.reload()

    }).catch(function (error) {
      // An error happened
      // ...
    });

  }



}
