import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    console.log('Check activate')

    if (this.authService.isLoggedIn !== true) {
      console.log('not sign in')
      document.location.replace('/membership/signin')
      return true;
    } else {
      if (document.getElementById('footer') !== null) {
        document.getElementById('footer').style.visibility = 'visible'
      }
      return true;
    }

  }

}
