import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';





@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  firebaseAuthUI : any

  constructor(private router : Router , private authService : AuthService) { }


  ngOnInit() {

    this.authService.initFirebaseAuth()

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    document.getElementById('footer').style.visibility = 'hidden'
  }

  signinWithEmail(form : NgForm){
    console.log('signinWithEmail')
    const data = form.value
    this.authService.signinWithEmail(data.username , data.password)
  }

  signinWithFacebook(){
    console.log('signinWithFacebook')
    this.authService.signinWithFacebook()
  }



}
