import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService } from '../service/connection.service';
import { FIREBASE_SERVICE_URL, FACEBOOK_SERVICE_URL, FACEBOOK_SERVICE_CONSTANT } from 'src/environments/constant';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  eventId = ""
  event: any = {}

  constructor(private activatedRoute: ActivatedRoute, private connectionService: ConnectionService) {
    this.activatedRoute.params.subscribe(params => {
      this.eventId = params['eventId'];
      this.getEventDetail()
    });
  }

  ngOnInit() {
  }

  getEventDetail() {

    this.event = {}

    this.connectionService.httpGetRequest(FIREBASE_SERVICE_URL.contentURL, 'getFacebookToken', '').subscribe((resp: any) => {
      let facebookToken = ""
      if (resp && resp.status == 'Complete') {
        facebookToken = resp.data.facebook_token
        this.connectionService.httpGetRequest(FACEBOOK_SERVICE_URL.facebookGraphURL, this.eventId, '?access_token=' + facebookToken + FACEBOOK_SERVICE_CONSTANT.facebookEventConstant).subscribe((resp: any) => {
          console.log('event', resp)
          this.event = resp
        })
      }
    })
  }

  locationNameForGoogleMap(name: any) {
    if (name != null) {
      let locationName = name.replaceAll(' ', '+')
      locationName = name.replaceAll(',', '%2C')
      return locationName
    }
  }

}
