import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FIREBASE_SERVICE_URL } from 'src/environments/constant';
import { ConnectionService } from '../service/connection.service';

@Component({
  selector: 'app-research-detail',
  templateUrl: './research-detail.component.html',
  styleUrls: ['./research-detail.component.scss']
})
export class ResearchDetailComponent implements OnInit {

  researchId = ""
  research : any = {}

  constructor(private activatedRoute: ActivatedRoute, private connectionService: ConnectionService) {
    this.activatedRoute.params.subscribe(params => {
      this.researchId = params['researchId'];
      console.log('researchId' , this.researchId)
      this.getResearchDetail()
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }


  getCoverUrl(){
    return "url('" + this.research.cover_url + "')"
  }

  getResearchDetail(){
    this.research = {}
    this.connectionService.httpGetRequest(FIREBASE_SERVICE_URL.contentURL, 'getResearchDetail', this.researchId).subscribe((resp: any) => {
      if (resp && resp.status == 'Complete') {
        this.research = resp.data
      }
      console.log('this.research = {}' , this.research)
    })
  }

  ngOnInit() {
  }

}
