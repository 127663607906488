// Live
const functionURL = 'https://us-central1-playground-next.cloudfunctions.net/'

// Develop
// const functionURL = 'http://localhost:5001/playground-next/us-central1/'

export const FIREBASE_SERVICE_URL = {
  contentURL : functionURL + 'contentAPI/',
  memberURL : functionURL + 'memberExtAPI/',
  adminURL : functionURL + 'playgroundAdminApp/',
  psuURL : functionURL + 'psuApp/'
}

export const FACEBOOK_SERVICE_URL = {
  facebookGraphURL : 'https://graph.facebook.com/'
}

export const FACEBOOK_SERVICE_CONSTANT = {
  facebookEventConstant : "&fields=cover%2Cstart_time%2Cname%2Cdescription%2Cplace%2Cend_time"
}

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyC2BqB3GS7-Tu5qRpGU1Nobi3tZhPGQNSg",
  authDomain: "playground-next.firebaseapp.com",
  databaseURL: "https://playground-next.firebaseio.com",
  projectId: "playground-next",
  storageBucket: "playground-next.appspot.com",
  messagingSenderId: "318236512522",
  appId: "1:318236512522:web:ff706a96cab74b9eb3eaf3",
  clientId : "318236512522-8o0c8figjbcfmq5g8aj67q0h0s682r39.apps.googleusercontent.com"
}

export const LABEL_CONSTANT = {
  LABEL_MONTH: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]
}

export const AUTH_UID_TYPE = {
  email : 'auth_email_uid' ,
  facebook : 'auth_facebook_uid' ,
  phone : 'auth_phone_uid'
}

export const IMAGE_ACTIVITIES_URL = "https://storage.googleapis.com/playground-next-content/activities"
