import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AngularFireAuth } from "@angular/fire/auth";

import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/do';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FacebookGraphApiService {

  constructor(private http: HttpClient) { }

  private facebookGraphURL = "https://graph.facebook.com"
  private client_id = ""
  private

  private extractData(res: Response, error: any) {
    let body: any = res;
    //console.log('Service complete');
    return body || {};
  }

  getRequest(parameter: any , token : any): Observable<any> {
    //console.log('Service : getRequest', service);

    const httpOptions = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    return this.http.get(this.facebookGraphURL + '/' + parameter + '&access_token=' + token, httpOptions).pipe(
      map(this.extractData)
    );
  }



}
