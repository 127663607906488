import { Component } from '@angular/core';
import { FIREBASE_CONFIG } from 'src/environments/constant';

import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AISPlayground';

  constructor(){

  }

  ngOnInit(): void {
    firebase.initializeApp(FIREBASE_CONFIG)
    // new firebaseui.auth.AuthUI(firebase.auth());
  }

}
