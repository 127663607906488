import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';

import { FIREBASE_CONFIG } from './../environments/constant'

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule, AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from "@angular/fire/auth";

import { MatDialogModule, MatDividerModule, MatSelectModule, MatLabel, MatInputModule, MatSliderModule, MatRadioModule, MatDatepickerModule, MatNativeDateModule, MatPaginatorModule, MatTableModule, MatTabsModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { MembershipModule } from './membership/membership.module';

import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AuthGuard } from './membership/services/auth.guard';
import { ResearchDetailComponent } from './research-detail/research-detail.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    EventDetailComponent,
    ResearchDetailComponent
  ],
  imports: [
    NgbModule.forRoot(),
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatTabsModule,
    MatDialogModule,
    MatSelectModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    ZXingScannerModule,
    MembershipModule,
    JwBootstrapSwitchNg2Module
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
