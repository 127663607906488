import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ActivityComponent } from './activity/activity.component';
import { ProfileComponent } from './profile/profile.component';
import { SigninComponent } from './signin/signin.component';
import { AuthGuard } from './services/auth.guard';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate : [AuthGuard]
      },
      {
        path: 'signin',
        component: SigninComponent
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate : [AuthGuard]
      },
      {
        path: 'activity',
        component: ActivityComponent,
        canActivate : [AuthGuard]
      },
      {
        path: 'profile',
        component: ProfileComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MembershipRoutingModule { }
