import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MembershipRoutingModule } from './membership-routing.module';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { ActivityComponent } from './activity/activity.component';
import { ProfileComponent } from './profile/profile.component';
import { FormsModule } from '@angular/forms';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { SigninComponent } from './signin/signin.component';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [FooterComponent, HomeComponent, LayoutComponent, ActivityComponent, ProfileComponent, SigninComponent],
  imports: [
    CommonModule,
    FormsModule,
    MembershipRoutingModule,
    JwBootstrapSwitchNg2Module,
    MatSelectModule
  ],
  providers: [AuthService, AuthGuard],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class MembershipModule { }
