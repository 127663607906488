import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatDialogRef, MatDialog, MatDialogState } from '@angular/material';

import * as firebase from 'firebase/app';



import QRCode from 'qrcode'
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  private toggleButton: any;
  private sidebarVisible: boolean;

  isSignIn = false
  displayname = ""
  uid = ""

  member : any = {}
  user: any = {}

  isFrontCard = true

  @ViewChild("cardTemplate", { static: true })
  private cardTemplate: TemplateRef<any>;
  cardTemplateRef: MatDialogRef<any>;

  openCardTemplate(): void {
    console.log('this.cardTemplateRef', this.cardTemplateRef)
    if (this.cardTemplateRef == undefined || this.cardTemplateRef.getState() == MatDialogState.CLOSED) {
      this.cardTemplateRef = this.dialog.open(this.cardTemplate, {
        width: '400px',
        height: '75vh',
        panelClass: 'card-dialog',
        maxWidth : '90vw'
      });

      this.cardTemplateRef.afterClosed();
      this.createQR();
    }
  }

  constructor(public location: Location, private element: ElementRef, public dialog: MatDialog, public router: Router ) {
    this.sidebarVisible = false;
  }

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
  }

  ngAfterViewInit(): void {
    // this.prepareMember()
  }

  async prepareMember(){
    this.member = await JSON.parse(localStorage.getItem('member'))
    if (this.member != null || this.member != undefined){
      this.isSignIn = true
    }else{
      this.isSignIn = false
    }
  }

  createQR() {

    var membercard = document.getElementById('membercard')
    console.log('membercard', membercard)
    var height = membercard.offsetHeight
    console.log('membercard height', height)

    var canvas = document.getElementById('qrcanvas')
    canvas.style.top = (height * 0.5).toString()
    canvas.style.position =

      QRCode.toCanvas(canvas, this.uid, function (error) {
        if (error) console.error(error)
        console.log('success!');




      })
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    html.classList.add('nav-open');

    this.sidebarVisible = true;
  };

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    // console.log(html);
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };

  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  };

  scroll(element) {
    console.log('click element', element)

    var elmnt = document.getElementById(element);
    console.log('elmnt', elmnt)
    elmnt.scrollIntoView({ block: 'start', behavior: 'smooth' });

    this.sidebarToggle()

    // window.scrollTo(element.yPosition)
  }


}
